<template>
  <div
    class="max-w-full lg:max-w-[960px] mx-auto px-4 lg:px-10 py-3 border-b border-gray-200 flex flex-col items-center"
  >
    <!-- Login Section -->
    <div
      class="w-full max-w-[512px] mx-auto px-6 py-8 mt-6 mb-4 bg-white overflow-hidden sm:rounded-lg"
    >
      <Loader v-if="isLoading" :loading-text="$t('login.signingIn')" />
      <div v-else>
        <div class="flex justify-center w-full mb-6">
          <div
            class="text-center text-neutral-900 text-[22px] font-normal leading-7"
          >
            {{ $t('login.pageTitle') }}
          </div>
        </div>

        <div class="flex justify-center w-full mt-8">
          <button
            class="w-[480px] h-12 bg-secondary text-white text-sm font-normal leading-[21px] tracking-tight rounded-xl flex items-center justify-center hover:bg-secondary-light"
            @click="signInWithGoogle"
          >
            {{ $t('login.googleSignIn') }}
          </button>
        </div>

        <div class="inline-flex items-center justify-center w-full">
          <hr class="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          <span
            class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900"
          >
            {{ $t('login.or') }}
          </span>
        </div>

        <form @submit.prevent="signInWithEmailAndPassword">
          <div class="flex justify-center w-full mb-4">
            <div
              class="w-[480px] text-xl text-left text-neutral-900 text-base font-normal leading-normal"
            >
              {{ $t('login.emailLabel') }}
            </div>
          </div>
          <div class="flex justify-center w-full mb-8">
            <input
              v-model="loginForm.email"
              type="email"
              :placeholder="$t('login.emailPlaceholder')"
              class="w-full bg-gray-100 text-slate-500 text-base font-normal leading-normal outline-none h-14 p-4 rounded-xl hover:bg-gray-200"
            />
          </div>

          <div class="flex justify-center w-full mb-4">
            <div
              class="w-[480px] text-xl text-left text-neutral-900 text-base font-normal leading-normal"
            >
              {{ $t('login.passwordLabel') }}
            </div>
          </div>
          <div class="flex justify-center w-full mb-8">
            <input
              v-model="loginForm.password"
              type="password"
              :placeholder="$t('login.passwordPlaceholder')"
              class="w-full bg-gray-100 text-slate-500 text-base font-normal leading-normal outline-none h-14 p-4 rounded-xl hover:bg-gray-200"
            />
          </div>

          <div class="flex justify-center w-full mt-8">
            <button
              :disabled="isLoading || !loginForm.email || !loginForm.password"
              :class="{
                'button-disabled':
                  isLoading || !loginForm.email || !loginForm.password,
              }"
              class="w-[480px] h-12 bg-secondary-dark text-white text-sm font-normal leading-[21px] tracking-tight rounded-xl flex items-center justify-center hover:bg-secondary"
              type="submit"
            >
              {{ $t('login.loginButton') }}
            </button>
          </div>

          <div class="flex justify-center w-full mt-4">
            <div
              class="text-center text-slate-500 text-sm font-normal leading-[21px]"
            >
              <a
                href="/password-reset"
                class="text-blue-500 hover:text-blue-700"
              >
                {{ $t('login.forgotPassword') }}
              </a>
            </div>
          </div>

          <div class="flex justify-center w-full mt-4">
            <div
              class="text-center text-slate-500 text-sm font-normal leading-[21px]"
            >
              {{ $t('login.newUser') }}
              <a href="/signup" class="text-blue-500 hover:text-blue-700">
                {{ $t('login.signUpHere') }}
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useAlertStore } from '@/stores/alert'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getFirebaseErrorMessage } from '@/utils/firebaseErrorHandler'

const router = useRouter()
const authStore = useAuthStore()
const { addAlert } = useAlertStore()
const { $firebaseAuth } = useNuxtApp()
const { t } = useI18n()

const isLoading = ref(false)
const loginForm = reactive({
  email: '',
  password: '',
})

const signInWithEmailAndPassword = () => {
  isLoading.value = true
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?authRedirect=true`,
  )
  authStore
    .signInWithEmailAndPassword(
      $firebaseAuth,
      loginForm.email,
      loginForm.password,
    )
    .then(async (firebaseUser) => {
      const idToken = await firebaseUser.getIdToken()
      return authStore.login(idToken, 'PetOwner')
    })
    .then(() => {
      router.push({ path: 'welcome' })
    })
    .catch((error) => {
      const errorMessage = getFirebaseErrorMessage(error.code, t)
      addAlert({
        type: 'error',
        message: errorMessage,
        duration: 10,
      })
    })
    .finally(() => {
      isLoading.value = false
    })
}

const signInWithGoogle = () => {
  isLoading.value = true
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?authRedirect=true`,
  )
  authStore
    .signInWithGoogle($firebaseAuth)
    .then(async (idToken) => {
      if (idToken) {
        return authStore.login(idToken, 'PetOwner')
      }
      throw new Error('No result from Google sign-in')
    })
    .then(() => {
      router.push({ path: 'welcome' })
    })
    .catch((error) => {
      const errorMessage = getFirebaseErrorMessage(error.code, t)
      addAlert({
        type: 'error',
        message: errorMessage,
        duration: 10,
      })
    })
    .finally(() => {
      isLoading.value = false
    })
}

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search)
  const isAuthRedirect = urlParams.get('authRedirect')

  if (isAuthRedirect) {
    isLoading.value = true
    authStore
      .listenForSSOSuccess($firebaseAuth)
      .then(async (result) => {
        if (result) {
          let idToken
          try {
            idToken = await result.getIdToken()
          } catch {
            idToken = result
          }
          return authStore.login(idToken, 'PetOwner')
        } else {
          throw new Error('No user found after redirect')
        }
      })
      .then(() => {
        router.push({ path: 'welcome' })
      })
      .catch((error) => {
        const errorMessage = getFirebaseErrorMessage(error.code, t)
        addAlert({
          type: 'error',
          message: errorMessage,
          duration: 10,
        })
      })
      .finally(() => {
        isLoading.value = false
        // Remove the query parameter after handling
        window.history.replaceState({}, '', window.location.pathname)
      })
  }

  const isAuthenticated = computed(() => authStore.isAuthenticated)
  if (isAuthenticated.value) {
    router.push({ name: 'welcome' })
  }
})
</script>
<style scoped>
.button-disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}
</style>
